import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Carousel } from "react-responsive-carousel"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import richText from "../../utils/richText"

import { Body, FeaturedHeading } from "../typography"

const Wrapper = styled.div`
  margin: 24px;
  border: 1px solid ${colors.creamLight};

  position: relative;

  max-width: 960px;

  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: -30px;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  @media ${breakpoints.tablet} {
    margin: 80px auto;

    &:after {
      bottom: -80px;
    }
  }
`

const IngredientWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`

const IngredientImageWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  padding: 32px;

  flex: 1 1 50%;
`

const IngredientTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  justify-content: center;
  align-items: center;

  background-color: ${colors.firLight};
  color: ${colors.creamLight};

  padding: 32px 24px;
  flex: 1 1 50%;

  img {
    max-width: 32px;
  }

  ${FeaturedHeading} {
    margin: 32px 0 16px;
  }

  ${Body} {
    text-align: center;
    max-width: 320px;
  }
`
const IconButton = styled.button`
  appearance: none;

  background: 0;
  border: 0;

  border-radius: 50%;

  outline: none;

  cursor: pointer;

  position: absolute;

  top: 30%;
  transform: translateY(-30%);

  z-index: 1;

  &:first-of-type {
    left: 20px;
  }

  &:last-of-type {
    right: 20px;
  }

  @media ${breakpoints.tablet} {
    top: 50%;
    transform: translateY(-50%);

    &:first-of-type {
      left: 15px;
    }

    &:last-of-type {
      right: unset;
      left: 415px;
    }
  }

  svg:last-of-type {
    display: none;
  }

  &:hover {
    svg:first-of-type {
      display: none;
    }

    svg:last-of-type {
      display: block;
    }
  }
`

function LeftArrow(clickHandler, hasPrev, label) {
  return (
    <IconButton onClick={clickHandler} aria-label={label}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27 20H13"
          stroke="#F8F0D8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 27L13 20L20 13"
          stroke="#F8F0D8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#F8F0D8"
        />
      </svg>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#F8F0D8" />
        <path
          d="M27 20H13"
          stroke="#020B08"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 27L13 20L20 13"
          stroke="#020B08"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconButton>
  )
}

function RightArrow(clickHandler, hasNext, label) {
  return (
    <IconButton onClick={clickHandler} aria-label={label}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 20H27"
          stroke="#F8F0D8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 13L27 20L20 27"
          stroke="#F8F0D8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="0.5"
          y="0.5"
          width="39"
          height="39"
          rx="19.5"
          stroke="#F8F0D8"
        />
      </svg>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#F8F0D8" />
        <path
          d="M13 20H27"
          stroke="#020B08"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 13L27 20L20 27"
          stroke="#020B08"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconButton>
  )
}

export default function IngredientHighlights({ ingredients }) {
  return (
    <Wrapper>
      <Carousel
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        renderArrowPrev={LeftArrow}
        renderArrowNext={RightArrow}
        infiniteLoop={true}
      >
        {[...ingredients, ...ingredients].map(ingredient => (
          <IngredientWrapper>
            <IngredientImageWrapper>
              <GatsbyImage
                alt={`Image of ${ingredient.ingredientName}`}
                image={ingredient.ingredientImage.gatsbyImage}
              />
            </IngredientImageWrapper>
            <IngredientTextWrapper>
              <img src={ingredient.illustration.url} />
              <FeaturedHeading>{ingredient.ingredientName}</FeaturedHeading>
              <Body as="div">
                {richText(ingredient.ingredientDescription.raw)}
              </Body>
            </IngredientTextWrapper>
          </IngredientWrapper>
        ))}
      </Carousel>
    </Wrapper>
  )
}
