import { graphql } from "gatsby"
import React from "react"
import ProductSlideshow from "../components/product/ProductSlideshow"
import Layout from "../components/layout"
import ProductDetails from "../components/product/ProductDetails"
import Product from "../components/product/Product"
import IngredientHighlights from "../components/product/IngredientHighlight"
import loadable from "@loadable/component"
import Seo from "../components/seo"
export const query = graphql`
  query Product($id: String) {
    contentfulProduct(id: { eq: $id }) {
      id
      productTitle
      shopifyId
      productImages {
        gatsbyImage(width: 640, height: 800, placeholder: BLURRED)
      }
      productDescription {
        raw
      }
      ingredients {
        raw
      }
      allergens {
        raw
      }
      ingredientHighlights {
        id
        ingredientName
        ingredientDescription {
          raw
        }
        ingredientImage {
          gatsbyImage(width: 400, height: 400, placeholder: BLURRED)
        }
        illustration {
          url
        }
      }
      product {
        variants {
          price
          shopifyId
          storefrontId
        }
      }
      recipes {
        slug
        alcoholic
        customerFavorite
        recipeName
        recipeDescription {
          raw
        }
        recipeImage {
          gatsbyImage(
            width: 560
            height: 960
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  }
`

const RecipeCarousel = loadable(
  () => import("../components/home/RecipeCarousel")
)

export default function ProductTemplate({ data }) {
  const { contentfulProduct: product } = data

  return (
    <Layout>
      <Seo title={product.productTitle} />
      <Product product={product} />
      <IngredientHighlights ingredients={product.ingredientHighlights} />
      <RecipeCarousel recipes={product.recipes} />
    </Layout>
  )
}
