import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import typography from "../../styles/typography"

const Wrapper = styled.div``

const AccordionButton = styled.button`
  appearance: none;
  background: 0;
  border: 0;
  padding: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  cursor: pointer;
`

const Title = styled.span`
  font-family: "Nib Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  color: ${colors.creamLight};
`
const Body = styled.div`
  color: ${colors.creamLight};
  font-size: 14px;

  margin: 0;
`

export default function AccordionItem({ title, children, open, onOpen }) {
  return (
    <Wrapper>
      <AccordionButton onClick={onOpen}>
        <Title>{title}</Title>
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={open ? "M13 6.5L7 0.5L1 6.5" : "M1 1L7 7L13 1"}
            stroke="#F8F0D8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </AccordionButton>
      {open && <Body>{children}</Body>}
    </Wrapper>
  )
}
